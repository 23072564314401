import React, { useEffect } from "react";
import bg3 from "../assect/images/bg/03.jpg"
import Navbar from "../components/navbar";
import AboutUs from "../components/about";
import Footer from "../components/footer";

import menudining from '../assect/images/menudining.jpg'

export default function SellSkyDining(){
    useEffect(() => {
        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            externalScript.id = "external";
            externalScript.async = true;
            externalScript.type = "text/javascript";
            externalScript.setAttribute("crossorigin", "anonymous");
            document.body.appendChild(externalScript);
            externalScript.src = 'https://d1ah56qj523gwb.cloudfront.net/widget/1.2.1/widget.min.js';
            externalScript.src = 'sha384-SYWte49En/51CagiEURWoKO+a4U1ZOvF4U5bqEuKX52fp/ikiP8onM2mZ4hxZfKg';
      };
      LoadExternalScript();
    });
    
    return(
        <>
        <Navbar navClass="defaultscroll sticky" logolight={true} menuClass = "navigation-menu nav-left nav-light"/>
        <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${bg3})`}}>
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            {/* <p className="text-white-50 para-desc mx-auto mb-0">Beli Tiket Disini</p> */}
                            <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Beli Tiket Skydining disini</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section">

            <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="title mb-3">SKY DINING GONDOLA ANCOL</h4>
                            <h5 className="title mb-3">Supported by Discovery Hotel</h5>
                        </div>
                        <div className="section-title text-justify align-items-justify mb-4 pb-2">
                        <p><b><i>Dining In The Sky  Event Season by Gondola Ancol</i></b> hadir untuk melengkapi momen spesial kamu bersama orang tersayang!
                        Yuk, ajak pasangan, teman, serta keluarga untuk merasakan sensasi The One and Only Sky Dining di Indonesia, hanya di Gondola Ancol.
                        Dimohon untuk membaca informasi di bawah ini dengan baik dan benar demi kenyamanan bersama
                        Sky Dining Gondola hanya dapat dipesan melalui www.gondola-ancol.com dengan sistem pembayaran online.
                        </p>
                        </div>
                        <div className="section-title text-center mb-4 pb-2">
                            <h5 className="title mb-3">Pelaksanaan Sky Dining :</h5>
                        </div>
                        <div className="section-title text-justify align-items-justify mb-4 pb-2">
                            <ul>
                                <li><b>Sky Dining Agustus 2024 pada tanggal terpilih pukul 11.00 - 12.30 WIB</b></li>
                                <li>Tanggal yang dipilih tidak bisa dilakukan <b><i>reschedule.</i></b></li>
                                <li>Maksimal waktu di dalam kabin Gondola adalah 90 menit atau 3 putaran Gondola.</li>
                                <li>Tanggal yang dipilih tidak bisa dilakukan <b><i>reschedule.</i></b></li>
                                <li>Limited Seat! Maksimal 20 kabin dalam satu waktu pelaksanaan. Pemesanan secara otomatis tidak dapat dilakukan apabila sudah sold out.</li>
                                <li><b>First Pay, First Get!</b> Tidak dapat di-booking, langsung melakukan pembayaran setelah memilih menu.</li>
                                <li>Harga paket Sky Dining hanya Rp.280,000 per orang. </li>
                                <li>Diharapkan untuk tidak terburu-buru saat memilih paket menu dan pilih sesuai hari kunjungan yang diinginkan.</li>
                                <li>Sesaat sebelum melakukan pembayaran, akan ada pertanyaan terkait pilihan menu</li>
                                <li>Maksimal 4 orang dalam 1 kabin.</li>
                                <li>Harap dapat <b>mengisi nomor telepon dengan benar saat pemesanan</b>, agar admin kami dapat menghubungi via Whatsapp terkait informasi saat di dalam kabin.</li>
                                <li>Setelah dihubungi oleh admin kami melalui Whatsapp, bisa kirimkan foto dan ucapan sesuai dengan keinginan peserta Sky Dining <b><i>(akan dihubungi H-1 sebelum pelaksanaan dining)</i></b></li>
                                <li>Semoga informasi yang kami berikan sudah lengkap dan jelas ya, Sahabat Gondola... Namun, apabila masih ada yang ingin ditanyakan terkait Sky Dining, dapat melalui direct message di Instagram @gondolaancol dan jangan lupa untuk follow!</li>
                                
                            <p className="para-desc mb-0 mx-auto"><i>*Jadwal Sky Dining sewaktu-waktu dapat berubah</i></p>
                            </ul>
                        
                        </div>
                        <div className="section-title text-center mb-4 pb-2">
                        <img src={menudining} alt="Gondola Ancol Promo Valentine" style={{width: "500px", margin: "0 auto", display: 'block', maxWidth: '100%', height: 'auto'}}/>
                        </div>
                        <div className="section-title text-center mb-4 pb-2">
                            <h5 className="title mb-3">Cara Penggunaan E-ticket : </h5>
                        </div>
                        <div className="section-title text-justify align-items-justify mb-4 pb-2">
                        <ul>
                            <li>Sebelum berkunjung, selain membeli Paket Sky Dining, pastikan Anda sudah membeli Tiket Masuk Ancol dan kendaraan di website ancol.com/tiket</li>
                            <li>Pada hari kunjungan pastikan e-ticket Anda sudah siap untuk ditunjukkan sebelum memasuki area tempat rekreasi</li>
                            <li>Anda dapat langsung masuk menggunakan e-ticket dengan memindai kode QR pada e-ticket Anda</li>
                            <li>Mohon menyesuaikan tingkat kecerahan layar telepon genggam Anda sebelum memindai kode QR</li>
                            <li>Hanya e-ticket Anda yang berlaku. Kwitansi atau bukti pembayaran tidak dapat digunakan sebagai pengganti e-ticket</li>
                        </ul>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center mt-4 pt-2">
                    <div className="col-lg-8 col-12">
                        <a id="___GOERS___widget" href="https://goersapp.com/venues/sky-dining-gondola-ancol--skydininggondola" data-background-color="transparent">Gondola Ancol</a>
                    </div>
                </div>

                {/* <div className="row justify-content-center mt-4 pt-2">
                    <div className="col-lg-8 col-12">
                        <div className="p-4 shadow rounded-3" role="form">
                            <ul className="list-unstyled d-flex justify-content-between mb-0">
                                <li className="h6 mb-0">Min $ 10000</li>
                                <li className="h6 mb-0">Max $ 200000</li>
                            </ul>

                            <div className="row">
                                <div className="col-sm-12 mb-4">
                                    <label htmlFor="slider" className="form-label"></label>
                                    <input type="range" onInput={handleChange} defaultValue="10000" min="10000" max="200000" className="form-range custom-range" id="slider"/>
                                </div>
                            </div>

                            <ul className="list-unstyled text-center d-md-flex justify-content-between mb-0">
                                <li>
                                    <ul className="mb-0 text-md-start brokerage-form list-unstyled">
                                        <li className="h5 mb-0"><label className="control-label">Total Value ($)</label></li>
                                        <li className="h5 mb-0"><input type="hidden" id="amount" className="form-control"/><span className="text-primary">$</span> <p className="mb-0 d-inline-block h5 text-primary" id="amount-label"></p></li>
                                    </ul>
                                </li>

                                <li className="mt-2 mt-sm-0">
                                    <ul className="mb-0 text-md-end brokerage-form list-unstyled">
                                        <li className="h5 mb-0"><label className="control-label">Brokerage Fee</label></li>
                                        <li className="h5 mb-0"><input type="hidden" id="saving" className="form-control mb-0"/><span className="text-primary">$</span> <p className="mb-0 d-inline-block h5 text-primary" id="saving-label"></p></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}

                
            </div>
        </section>
        <Footer/>
        </>
    )
}